import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Mobile Application Development Company in Delhi NCR, India, USA & UK"
      description="Mobile Application Development is one of the core services offered by D2i. D2i is one of the leading company in India specializing in mobile application development."
    />
    <div style={{ textAlign: "justify" }}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h1>Mobile Application Development Company</h1>
            </div>
            <p>
              At D2i Technology as a mobile app development company, we provide
              mobile application development services. We build apps for all
              stakeholders of your business - i.e. prospects, customers,
              employees, partners and vendors to ensure that you extend your
              business functionality to them in real time in a connected world
              as mobile apps are the need of your business now. We do both
              native and hybrid apps. We can help you build interactive apps and
              content on consumption across these channels and form factors to
              create more engaging experiences with your audience.&nbsp;
            </p>
            <p>
              We have Mobile Application Developers who add life to your old or
              new mobile applications by creating an interactive, dynamic and
              easy to use App for customers. We develop Mobile applications for
              all business domains by doing a thorough ground work and
              brainstorming. We convert imaginations, ideas and requirements of
              the clients into XD designs and then to real life Mobile
              applications. Our team follows the latest tech stacks, latest
              designs and follows best coding practices to deliver quality
              mobile applications timely.
            </p>
            <p>
              D2i Technology is the one-stop solution provider for you in all
              aspects. Our expert team is available 24X7 for any support
              required which makes us one of the best mobile application
              development companies.
            </p>
            <p className="mb-2">We can develop mobile application in:</p>
            <ul>
              <li>Flutter</li>
              <li>Kotlin</li>
              <li>iOS</li>
              <li>Android</li>
              <li>Windows</li>
              <li>PhoneGap</li>
              <li>Xamarin</li>
              <li>Titanium</li>
              <li>Ionic</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
